import { Tooltip, TooltipPlacement, Typography } from '@ezetech/swag-space-x';

import { useAppSelector } from 'redux/store';
import { CLIENT_STATUSES } from 'enums/orders.enum';
import { statusComponentSelector } from 'redux/selectors/orders.selectors';
import {
  isOrderReviewMockupsByCustomerAllowedSelector,
  isOrderReviewPantonesByCustomerAllowedSelector,
} from 'redux/selectors/orders.selectors';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import downloadIcon from '../../../../../../assets/svg/file.svg';
import trackingNumberIcon from '../../../../../../assets/svg/tracking-number.svg';

import { StatusRow } from '../status-row';

import css from '../../order-card-item.module.scss';

interface IProps {
  orderId: string;
  itemId: string;
}

export const StatusComponent = ({ orderId, itemId }: IProps): JSX.Element => {
  const { status, statusCode, trackingNumber, trackingNumberLink, productionMockupLink } =
    useAppSelector(statusComponentSelector({ orderId, itemId }));
  const isOrderReviewPantonesByCustomerAllowed = useAppSelector(
    isOrderReviewPantonesByCustomerAllowedSelector(orderId),
  );
  const isOrderReviewMockupsByCustomerAllowed = useAppSelector(
    isOrderReviewMockupsByCustomerAllowedSelector({ orderId, itemId }),
  );

  const showTrackingNumberLink =
    Boolean(trackingNumberLink) && status !== CLIENT_STATUSES.TRACKING_NUMBER;

  return (
    <div className={css.status}>
      <Typography
        lineHeight="170%"
        fontType="bodyMd"
        color={COLORS.colorSecondaryText}
        className={css.label}
      >
        Status:
      </Typography>
      <div className={css.row}>
        <Typography
          lineHeight="170%"
          fontType="bodyMdBold"
          color={COLORS.colorPrimaryText}
          className={css.value}
        >
          <StatusRow
            status={status}
            statusCode={statusCode}
            trackingNumberLink={trackingNumberLink}
            orderId={orderId}
            itemId={itemId}
            isReviewPantonesByCustomerAllowed={isOrderReviewPantonesByCustomerAllowed}
            isReviewMockupsByCustomerAllowed={isOrderReviewMockupsByCustomerAllowed}
          />
        </Typography>
        {Boolean(productionMockupLink) && (
          <Tooltip
            placement={TooltipPlacement.right}
            content={
              <a href={productionMockupLink} target="_blank" rel="noreferrer noopener">
                <img src={downloadIcon} alt="infoIcon" className={css.downloadIcon} />
              </a>
            }
          >
            <Typography
              lineHeight="150%"
              fontType="bodySm"
              color={COLORS.colorPrimaryText}
            >
              Download production mockup
            </Typography>
          </Tooltip>
        )}
        {showTrackingNumberLink && (
          <Tooltip
            placement={TooltipPlacement.right}
            content={
              <a href={trackingNumberLink} target="_blank" rel="noreferrer noopener">
                <img
                  src={trackingNumberIcon}
                  alt="trackingNumberIcon"
                  className={css.trackingNumberIcon}
                />
              </a>
            }
          >
            <Typography
              lineHeight="150%"
              fontType="bodySm"
              color={COLORS.colorPrimaryText}
            >
              Tracking Number: {trackingNumber}
            </Typography>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
