import { IFile } from '@ezetech/swag-space-x';
import { isMobile } from 'utils/ui.util';
import { removeEmptyFieldsFromObject } from 'utils/common.util';
import {
  IHomepageSettingsPromoProduct,
  IHomepageSettingsCategories,
  IHomepageSettingsBrandLogos,
  IHomepageSettings,
  ColorType,
  ButtonColorType,
} from 'interfaces/homepage-settings.interface';

export const isTabletDevice = () => isMobile(1023);
export const isMobileDevice = () => isMobile(767);
export const isSmallMobileDevice = () => isMobile(381);

type QueryBuilderProps = IHomepageSettings & {
  storeLink?: string;
};

export const queryBuilder = (props: QueryBuilderProps): string => {
  const { storeLink, ...homepageProps } = props;
  const availableFields = [
    'heroBlock',
    'brandLogosBlock',
    'aboutUsBlock',
    'categoriesBlock',
    'promoProductBlock',
    'imagesRowBlock',
    'brandsWeOfferBlock',
    'contactFormBlock',
  ];

  const newObject = removeEmptyFieldsFromObject(homepageProps as Record<string, unknown>);

  const parseProducts = (value: unknown): string => {
    const data = value as unknown as IHomepageSettingsPromoProduct;

    return JSON.stringify({
      isHidden: data.isHidden,
      headingText: data.headingText,
      productsPublicIds: data.products.map((product) => product.publicId),
    });
  };

  const parseCategory = (value: unknown): string => {
    const data = value as unknown as IHomepageSettingsCategories;

    return JSON.stringify({
      isHidden: data.isHidden,
      headingText: data.headingText,
      categories: data.categories
        .map((category) => ({
          categoryPublicId: category.publicId,
          imgUrl: category.image?.url || '',
          categoryName: category.name,
        }))
        .filter(Boolean),
    });
  };

  const parseWithImage = (value: unknown): string => {
    const { image, images, ...data } = value as Record<string, unknown>;
    if (image && 'url' in (image as Record<string, unknown>)) {
      return JSON.stringify({
        ...data,
        imgUrl: (image as Record<string, unknown>)?.url || '',
      });
    }
    if (images && Array.isArray(images)) {
      return JSON.stringify({
        ...data,
        imgUrls: images
          .map((img) => (img as Record<string, unknown>)?.url || '')
          .filter(Boolean),
      });
    }

    return JSON.stringify(data);
  };

  const parseBrandLogos = (value: unknown): string => {
    const { images, ...data } = value as unknown as IHomepageSettingsBrandLogos;
    return JSON.stringify({
      ...data,
      logos: images.map((img) => img?.url || '').filter(Boolean),
    });
  };

  const queryProps = Object.keys(newObject).reduce<Array<string>>((acc, key) => {
    if (!availableFields.includes(key)) {
      return acc;
    }
    let value;
    if (newObject[key] !== 'object') {
      value = newObject[key];
    }

    switch (key) {
      case 'promoProductBlock':
        value = parseProducts(newObject[key]);
        break;
      case 'categoriesBlock':
        value = parseCategory(newObject[key]);
        break;
      case 'brandLogosBlock':
        value = parseBrandLogos(newObject[key]);
        break;
      default:
        value = parseWithImage(newObject[key]);
    }

    acc.push(`${key}=${encodeURIComponent(value)}`);
    return acc;
  }, []);

  const query = `${storeLink}?isDashboardPreview=true&${queryProps.join('&')}`;
  return query.replace(/'/g, ';apos;');
};

const fillArray = (existsArray: Array<IFile | null> | undefined, length = 8) => {
  return Array.from({ length }, () => '').map((item, index) => {
    return (existsArray?.length && existsArray[index]) || null;
  });
};

export const getHomepageObject = (data: IHomepageSettings) => {
  return {
    heroBlock: {
      headingText: data.heroBlock?.headingText || '',
      bodyText: data.heroBlock?.bodyText || '',
      textColorType: data.heroBlock?.textColorType || ColorType.Light,

      buttonText: data.heroBlock?.buttonText || '',
      buttonTextColorType: data.heroBlock?.buttonTextColorType || ColorType.Light,
      buttonColorType: data.heroBlock?.buttonColorType || ButtonColorType.Primary,

      image: data.heroBlock?.image || null,
    },
    brandLogosBlock: {
      isHidden: data.brandLogosBlock?.isHidden || false,
      headingText: data.brandLogosBlock?.headingText || '',
      images: fillArray(data.brandLogosBlock?.images, 8),
    },
    aboutUsBlock: {
      isHidden: data.aboutUsBlock?.isHidden || false,
      headingText: data.aboutUsBlock?.headingText || '',
      bodyText: data.aboutUsBlock?.bodyText || '',
      buttonText: data.aboutUsBlock?.buttonText || '',
      buttonTextColorType: data.aboutUsBlock?.buttonTextColorType || ColorType.Light,
      buttonColorType: data.aboutUsBlock?.buttonColorType || ButtonColorType.Primary,
      buttonLink: data.aboutUsBlock?.buttonLink || '',
      image: data.aboutUsBlock?.image || null,
    },
    categoriesBlock: {
      isHidden: data.categoriesBlock?.isHidden || false,
      headingText: data.categoriesBlock?.headingText || '',

      categories: data.categoriesBlock?.categories || [],
    },
    promoProductBlock: {
      isHidden: data.promoProductBlock?.isHidden || false,
      headingText: data.promoProductBlock?.headingText || '',
      products: data.promoProductBlock?.products || [],
    },
    imagesRowBlock: {
      isHidden: data.imagesRowBlock?.isHidden || false,
      images: fillArray(data.imagesRowBlock?.images, 3),
    },
    brandsWeOfferBlock: {
      isHidden: data.brandsWeOfferBlock?.isHidden || false,
    },
    contactFormBlock: {
      isHidden: data.contactFormBlock?.isHidden || false,
      headingText: data.contactFormBlock?.headingText || '',
      bodyText: data.contactFormBlock?.bodyText || '',
    },
  };
};
