import { createSelector } from 'reselect';

import { RootState } from '../store';

export const withdrawSelector = (state: RootState) => state.withdraw;

export const incomingMoneySelector = createSelector(
  withdrawSelector,
  (state) => state.income,
);
export const pendingMoneySelector = createSelector(
  withdrawSelector,
  (state) => state.pending,
);
export const availableMoneySelector = createSelector(
  withdrawSelector,
  (state) => state.available,
);
