import { pushNotification } from 'redux/slices/notifications.slice';
import { makeToastNotification } from 'utils/query.util';
import {
  PER_PAGE_CARTS,
  DEFAULT_PAGE,
  DEFAULT_SORTING_ORDER,
} from 'constants/carts.constant';
import { presentationsApi } from 'redux/api/presentation.api';
import { fetchUserData } from 'utils/user.util';
import { fetchStoreSettingsData } from 'utils/store-settings.util';
import { assigneeApi } from 'redux/api/assignee.api';
import { settingsApi } from 'redux/api/settings.api';
import { IAction } from 'interfaces/loaders.interface';
import { store } from '../../redux/store';
import { can, SUBJECTS } from '../../boot/ability';

export async function presentationsLoader(): Promise<null> {
  await fetchUserData();
  await fetchStoreSettingsData();

  try {
    const requests: Record<string, unknown>[] = [
      {
        endpoint: presentationsApi.endpoints.getPresentations.initiate({
          page: DEFAULT_PAGE,
          perPage: PER_PAGE_CARTS,
          search: '',
          sorting: '',
          sortingOrder: DEFAULT_SORTING_ORDER,
          dropdown: [],
        }),
      },
      {
        endpoint: settingsApi.endpoints.getBillingAddress.initiate(),
        condition: can(
          SUBJECTS.BILLING_ADDRESS.actions.BILLING_ADDRESS_VIEW,
          SUBJECTS.BILLING_ADDRESS.value,
        ),
      },
      {
        endpoint: settingsApi.endpoints.getInvoice.initiate(),
        condition: can(
          SUBJECTS.INVOICE_BANK_DETAILS.actions.INVOICE_BANK_DETAILS_VIEW,
          SUBJECTS.INVOICE_BANK_DETAILS.value,
        ),
      },
      {
        endpoint: assigneeApi.endpoints.getAssigneeListForPresentations.initiate(),
      },
    ];

    const results = await Promise.all(
      requests.map(async (request) => {
        if (request?.condition === false) {
          return;
        }

        return store.dispatch(request.endpoint as IAction).unwrap();
      }),
    );

    if (results.some((result) => result && 'error' in result)) {
      return null;
    }
  } catch (e) {
    store.dispatch(pushNotification(makeToastNotification(e)));
  }

  return null;
}
