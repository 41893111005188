import classNames from 'classnames';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { TabsNavigation } from '@ezetech/swag-space-x';
import { canRoute } from 'boot/ability';
import { myOrdersCountSelector } from 'redux/selectors/carts.selector';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { ROUTES } from 'constants/router';
import { resetFilters } from 'redux/slices/filter.slice';
import { PageContent } from 'components/page-content';

import css from './my-orders-layout.module.scss';

export function MyOrdersLayout(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const pathname = `/${location.pathname.split('/').reverse()[0]}`;

  const myOrdersCounts = useAppSelector(myOrdersCountSelector);

  const ORDERS_TABS = [
    {
      id: ROUTES.MY_STORE_ORDERS,
      name: `Your Store (${myOrdersCounts.orders})`,
    },
    {
      id: ROUTES.CBS_ORDERS,
      name: `Curated Brand Sites (${myOrdersCounts.cbsOrders})`,
    },
  ];

  const handleChangeTab = (tab: string) => {
    dispatch(resetFilters());
    const route = Object.values(ROUTES).find((r) => r.endsWith(tab)) || '';
    navigate(route);
  };

  return (
    <PageContent>
      {myOrdersCounts.cbsOrders > 0 && (
        <TabsNavigation
          mobileTriggerClassName={css.navigateMobileTrigger}
          selectedTab={pathname}
          onChangeTab={handleChangeTab}
          tabs={ORDERS_TABS.filter((item) => {
            const route = item.id;
            const result = canRoute(route);
            return result.isAllowed && !result.redirect;
          })}
        />
      )}
      <div
        className={classNames(css.outletWrapper, {
          [css.outletWrapperOnlyMyOrders]: !(myOrdersCounts.cbsOrders > 0),
        })}
      >
        <div className={css.outletWrapperInner}>
          <Outlet />
        </div>
      </div>
    </PageContent>
  );
}
