type OmitKey<T, K extends string> = K extends `${infer Parent}.${infer Rest}`
  ? Parent extends keyof T
    ? {
        [P in keyof T]: P extends Parent ? OmitKey<T[P], Rest> : T[P];
      }
    : T
  : K extends keyof T
    ? Omit<T, K>
    : T;

export function deepOmit<T extends object, K extends string>(
  obj: T,
  keyToOmit: K,
): OmitKey<T, K> {
  const [currentKey, ...restKeys] = keyToOmit.split('.');
  const restPath = restKeys.join('.');

  if (currentKey === '*') {
    if (Array.isArray(obj)) {
      return obj.map((item) =>
        typeof item === 'object' && item !== null ? deepOmit(item, restPath) : item,
      ) as OmitKey<T, K>;
    }
    return obj as OmitKey<T, K>;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) =>
      typeof item === 'object' && item !== null ? deepOmit(item, keyToOmit) : item,
    ) as OmitKey<T, K>;
  }

  if (restKeys.length === 0) {
    const { [currentKey as never]: _, ...rest } = obj;
    return rest as OmitKey<T, K>;
  }

  return {
    ...Object.fromEntries(
      Object.entries(obj).map(([key, value]) => {
        if (key === currentKey && typeof value === 'object' && value !== null) {
          return [key, deepOmit(value, restPath)];
        }
        return [key, value];
      }),
    ),
  } as OmitKey<T, K>;
}
