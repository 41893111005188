import cn from 'classnames';
import {
  Checkbox,
  Input,
  TextField,
  Dropzone,
  Typography,
  Select,
  OptionType,
} from '@ezetech/swag-space-x';
import { CSSObjectWithLabel } from 'react-select';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import {
  MAX_FILE_SIZE_10MB_RESTRICTION,
  JPEG_PNG_ACCEPT_RESTRICTION,
} from 'constants/limits';
import { useProcessFile } from 'hooks/use-process-file';

import {
  BUTTON_COLOR_TYPE_OPTIONS,
  COLOR_TYPE_OPTIONS,
} from 'constants/homepage-editor.constants';
import { ButtonColorType } from 'interfaces/homepage-settings.interface';
import { useAppSelector } from 'redux/store';
import { brandColorsSelector } from 'redux/selectors/settings.selectors';
import { isValidUrl } from 'validations/is-valid-url.validation';
import { SidebarAboutUsBlockProps } from '../interface';

import css from './sidebar.module.scss';
import { EDITOR_ID, TABLET_WIDTH } from './hero-block';

export const SidebarAboutUsBlock = (props: SidebarAboutUsBlockProps): JSX.Element => {
  const { settings, setChanges, aboutUsBlockButtonErrors, setAboutUsBlockButtonErrors } =
    props;
  const { handleProcessFile } = useProcessFile();
  const brandColors = useAppSelector(brandColorsSelector);

  const getButtonColorOptionByValue = (value: string) =>
    BUTTON_COLOR_TYPE_OPTIONS.find((option) => option.value === value) ?? null;

  const getColorOptionByValue = (value: string) =>
    COLOR_TYPE_OPTIONS.find((option) => option.value === value) ?? null;

  const calculatePosition = (style: CSSObjectWithLabel) => {
    const rect = document.getElementById(EDITOR_ID)?.getBoundingClientRect();
    const { top, left, ...rest } = style;

    if (window.innerWidth >= TABLET_WIDTH) {
      return { top, left, ...rest };
    }

    return {
      ...rest,
      left: (left as number) - (rect?.x || 0),
      top: (top as number) - (rect?.y || 0),
    };
  };

  const handleButtonTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChanges('buttonText', e.target.value);
    if (!e.target.value && settings.buttonLink) {
      setAboutUsBlockButtonErrors({
        buttonText: 'Should not be empty',
        buttonLink: '',
      });
    } else if (e.target.value && !settings.buttonLink) {
      setAboutUsBlockButtonErrors({
        buttonText: '',
        buttonLink: 'Should not be empty',
      });
    } else {
      setAboutUsBlockButtonErrors({
        buttonText: '',
        buttonLink: '',
      });
    }
  };

  const handleButtonLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChanges('buttonLink', e.target.value);
    const isValid = isValidUrl(e.target.value);
    const buttonLinkError = e.target.value && !isValid ? 'Should be a valid URL' : '';
    if (!e.target.value && settings.buttonText) {
      setAboutUsBlockButtonErrors({
        buttonLink: 'Should not be empty',
        buttonText: '',
      });
    } else if (e.target.value && !settings.buttonText) {
      setAboutUsBlockButtonErrors({
        buttonLink: buttonLinkError,
        buttonText: 'Should not be empty',
      });
    } else {
      setAboutUsBlockButtonErrors({
        buttonText: '',
        buttonLink: buttonLinkError,
      });
    }
  };

  return (
    <div className={css.sidebarMainBlock}>
      <Checkbox
        onCheckedChange={(e) => setChanges('isHidden', e)}
        name="isHidden"
        checked={settings.isHidden}
        label="Hide section"
      />
      <div
        className={cn(css.disabledBlock, { [css.disabledOverlay]: settings.isHidden })}
      />
      <div>
        <Input
          autoComplete="off"
          value={settings.headingText}
          className={css.inputWrapper}
          maxLength={20}
          onChange={(e) => setChanges('headingText', e.target.value)}
          name="headingText"
          placeholder="About Us"
          label="Heading Copy"
          skipError
        />
        <Typography
          fontType="bodySm"
          color={COLORS.colorText500}
          className={css.subInputInfo}
          lineHeight="142.857%"
        >
          Max 20 characters
        </Typography>
      </div>

      <div>
        <TextField
          value={settings.bodyText}
          maxLength={200}
          onChange={(e) => setChanges('bodyText', e.target.value)}
          name="bodyText"
          placeholder="Write some text here... e.g. We were founded with a simple goal: to be the best place to buy and manage quality promotional products that people..."
          label="Body Copy"
          className={css.textarea}
          labelClassName={css.textareaLabel}
          textAreaWrapperClassName={css.textareaWrapper}
        />
        <Typography
          fontType="bodySm"
          color={COLORS.colorText500}
          className={css.subInputInfo}
          lineHeight="142.857%"
        >
          Max 200 characters
        </Typography>
      </div>
      <div>
        <Input
          autoComplete="off"
          className={css.inputWrapper}
          value={settings.buttonText}
          maxLength={15}
          onChange={handleButtonTextChange}
          name="buttonText"
          placeholder="Button"
          label="Button Text"
          errors={aboutUsBlockButtonErrors.buttonText}
          skipError
        />
        {aboutUsBlockButtonErrors.buttonText ? (
          <Typography
            fontType="bodySm"
            color={COLORS.colorErrorText}
            className={css.subInputInfo}
            lineHeight="142.857%"
          >
            {aboutUsBlockButtonErrors.buttonText}
          </Typography>
        ) : (
          <Typography
            fontType="bodySm"
            color={COLORS.colorText500}
            className={css.subInputInfo}
            lineHeight="142.857%"
          >
            Max 15 characters
          </Typography>
        )}
      </div>
      <div>
        <Input
          autoComplete="off"
          className={css.inputWrapper}
          value={settings.buttonLink}
          maxLength={100}
          onChange={handleButtonLinkChange}
          name="buttonLink"
          placeholder="https://www.your-homepage.com/"
          label="Button Link"
          errors={aboutUsBlockButtonErrors.buttonLink}
          skipError
        />
        {aboutUsBlockButtonErrors.buttonLink ? (
          <Typography
            fontType="bodySm"
            color={COLORS.colorErrorText}
            className={css.subInputInfo}
            lineHeight="142.857%"
          >
            {aboutUsBlockButtonErrors.buttonLink}
          </Typography>
        ) : (
          <Typography
            fontType="bodySm"
            color={COLORS.colorText500}
            className={css.subInputInfo}
            lineHeight="142.857%"
          >
            Max 100 characters
          </Typography>
        )}
      </div>
      <div>
        <Select
          menuPosition="fixed"
          menuPlacement="auto"
          menuShouldBlockScroll
          styles={{
            menuPortal: (style) => ({
              ...style,
              ...calculatePosition(style),
            }),
          }}
          options={BUTTON_COLOR_TYPE_OPTIONS}
          value={getButtonColorOptionByValue(settings.buttonColorType)}
          onChange={(value) => setChanges('buttonColorType', (value as OptionType).value)}
          label="Button Color"
          name="buttonColorType"
          components={{
            ValueContainer: ({ children, ...propsValue }) => (
              <div className={css.navOptionsMobileValueContainer} {...propsValue}>
                <span
                  className={css.circle}
                  style={{
                    backgroundColor:
                      getButtonColorOptionByValue(settings.buttonColorType)?.value ===
                      ButtonColorType.Primary
                        ? brandColors.primaryColor
                        : brandColors.secondaryColor,
                  }}
                />
                {children}
              </div>
            ),
            Option: ({ innerProps, data }) => (
              <div
                {...innerProps}
                data-label={data?.value}
                className={cn(css.navOptionsMobileOption, {
                  [css.selectedOption]:
                    getButtonColorOptionByValue(settings.buttonColorType)?.value ===
                    data?.value,
                })}
              >
                <span
                  className={css.circleOption}
                  style={{
                    backgroundColor:
                      data?.value === ButtonColorType.Primary
                        ? brandColors?.primaryColor
                        : brandColors?.secondaryColor,
                  }}
                ></span>
                {data?.label}
              </div>
            ),
          }}
          skipError
        />
      </div>
      <div className={css.lastSelect}>
        <Select
          menuPosition="fixed"
          menuPlacement="auto"
          menuShouldBlockScroll
          options={COLOR_TYPE_OPTIONS}
          value={getColorOptionByValue(settings.buttonTextColorType)}
          styles={{
            menuPortal: (style) => ({
              ...style,
              ...calculatePosition(style),
            }),
          }}
          onChange={(value) =>
            setChanges('buttonTextColorType', (value as OptionType).value)
          }
          label="Button Text Color"
          name="buttonTextColorType"
          skipError
        />
      </div>
      <div>
        <Typography
          fontType="bodySm"
          color={COLORS.colorPrimaryText}
          className={css.dropzoneLabel}
          lineHeight="142.857%"
        >
          Upload Image
        </Typography>
        <Dropzone
          buttonClassName={css.dropzoneButton}
          accept={JPEG_PNG_ACCEPT_RESTRICTION}
          className={css.imageLoader}
          files={settings.image ? [settings.image] : []}
          maxSize={MAX_FILE_SIZE_10MB_RESTRICTION}
          processFile={handleProcessFile}
          uploadTextWrapperClassName={css.uploadTextWrapper}
          onFilesChanged={(files) => {
            if (!files[0] && !settings.image?.url) {
              return;
            }
            setChanges('image', files[0] || null);
          }}
          additionalText="JPG or PNG, no more than 10MB"
          previewClassName={css.previewImageWrapper}
          helperText="Recommended size is 1200 x 874 pixels"
          helperTextClassName={css.dropzoneHelperText}
        />
      </div>
    </div>
  );
};
