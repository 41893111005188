import {
  Dialog,
  DialogContentWrapper,
  DialogFooter,
  DialogTitle,
  SwagButton,
  Typography,
} from '@ezetech/swag-space-x';
import { useEffect, useState } from 'react';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import { ReviewColorsPopupProps } from 'interfaces/popup.interface';
import { PMS2HEX } from 'utils/colors.util';
import { ROUTES } from 'constants/router';
import { useApprovePantoneColorsMutation } from 'redux/api/orders.api';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { useAppDispatch } from '../../../redux/store';
import { REVIEW_COLORS_SUCCESS_POPUP } from '../_logic/popups-list';
import classes from './review-colors.popup.module.scss';
import { ColorPicker } from './components/color-picker';
import { ColorsView } from './components/colors-view';

export interface IPantoneColorsProps {
  pms: string;
  id: number;
  hex: string;
}

export const ReviewColorsPopup = ({
  orderId,
  item,
  isReviewPantonesByCustomerAllowed,
  isOpen,
  isCBSOrder,
}: ReviewColorsPopupProps) => {
  const dispatch = useAppDispatch();
  const [approvePantoneColors] = useApprovePantoneColorsMutation();
  const isMultiLogos = item?.pantones && item?.pantones?.length > 1;

  function uniqueID() {
    return Math.floor(Math.random() * Date.now());
  }
  const [currentMockupIndex, setCurrentMockupIndex] = useState(0);
  const [pantones, setPantones] = useState(item?.pantones);
  const [colors, setColor] = useState<Array<IPantoneColorsProps>>(
    item?.pantones?.length
      ? item?.pantones[currentMockupIndex].colorsToPrint.map((pantone) => ({
          pms: pantone,
          id: uniqueID(),
          hex: `#${PMS2HEX(pantone)}`,
        }))
      : [],
  );
  const [newColor, setNewColor] = useState('');
  const handleOpenChange = () => {
    dispatch(closePopup());
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewColor(e.target.value);
  };

  const handleAddColor = () => {
    if (!newColor.length) {
      return;
    }
    setColor([
      ...colors,
      { pms: newColor, id: uniqueID(), hex: `#${PMS2HEX(newColor)}` },
    ]);
    setNewColor('');
  };

  const handleRemove = (color: IPantoneColorsProps) => {
    if (colors.length > 1) {
      setColor(colors.filter((existedColor) => color.id !== existedColor.id));
    }
  };
  const saveColorsForMockup = () => {
    if (pantones) {
      const updatedPantones = [...pantones];

      const colorsToPrint = colors.map((color) => color.pms);
      updatedPantones[currentMockupIndex] = {
        ...updatedPantones[currentMockupIndex],
        colorsToPrint,
      };
      setPantones(updatedPantones);
    }
  };
  const handleContinue = () => {
    saveColorsForMockup();
    setCurrentMockupIndex(currentMockupIndex + 1);
  };

  const onApproveColors = () => {
    if (item?.id && orderId && pantones) {
      const updatedPantones = [...pantones];

      const colorsToPrint = colors.map((color) => color.pms);
      updatedPantones[currentMockupIndex] = {
        ...updatedPantones[currentMockupIndex],
        colorsToPrint,
      };
      approvePantoneColors({
        itemId: item.id,
        orderId,
        itemPantones: updatedPantones,
      });
    }
  };

  const onConfirm = () => {
    saveColorsForMockup();
    onApproveColors();
    dispatch(closePopup());
    dispatch(
      openPopup({
        popupName: REVIEW_COLORS_SUCCESS_POPUP,
        popupProps: { isReviewPantonesByCustomerAllowed },
      }),
    );
  };

  useEffect(() => {
    setColor(
      item?.pantones?.length
        ? item?.pantones[currentMockupIndex].colorsToPrint.map((pantone) => ({
            pms: pantone,
            id: uniqueID(),
            hex: `#${PMS2HEX(pantone)}`,
          }))
        : [],
    );
  }, [currentMockupIndex]);

  const orderNumLink = isCBSOrder ? ROUTES.CBS_ORDERS : ROUTES.MY_STORE_ORDERS;

  return (
    <Dialog className={classes.dialog} open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContentWrapper
        className={classes.dialogContentWrapperClassName}
        overlayClassName={classes.dialogOverlayClassName}
      >
        <DialogTitle>Review Pantones</DialogTitle>
        <div className={classes.main}>
          <div
            className={classes.mockupBlock}
            style={{ backgroundColor: item?.variants[0].color }}
          >
            <div className={classes.previewWrapper}>
              <img
                src={item?.pantones?.[currentMockupIndex]?.logoPreview || ''}
                className={classes.logo}
              />
            </div>
            {isMultiLogos && (
              <div className={classes.pageCounter}>
                Design {`${currentMockupIndex + 1} of ${pantones?.length}`}
              </div>
            )}
          </div>
          <div className={classes.mockupInfoBlock}>
            <div className={classes.orderLink}>
              <Typography color="grey" className={classes.subTitle}>
                {' '}
                Review Pantone colors for the {item?.name} in order number &nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={orderNumLink}
                  className={classes.link}
                >
                  #{item?.num}
                </a>
                .{' '}
                {isReviewPantonesByCustomerAllowed
                  ? `Once you approve the Pantones, we will send this to your customer to
                approve. Once approved, we will create a mockup for you to approve.`
                  : 'Once approved, we will start on the production Mockup.'}
              </Typography>
              <div className={classes.itemCard}>
                <div className={classes.itemMockup}>
                  <img src={item?.pantones?.[currentMockupIndex]?.mockupPreview || ''} />
                </div>
                <div>
                  <Typography color="grey" className={classes.subTitle}>
                    Confirming colors for:
                  </Typography>
                  <p className={classes.itemName}>{item?.name}</p>
                </div>
                <div className={classes.labelWrapper}>
                  <Typography
                    className={classes.label}
                    fontType="bodySm"
                    color={COLORS.colorPrimaryText}
                    lineHeight="142.857%"
                  >
                    {item?.pantones?.[currentMockupIndex].location}
                  </Typography>
                </div>
              </div>

              <div className={classes.colorsBlock}>
                <p className={classes.colorsTitle}>
                  There are {colors.length} Pantone Colors for {item?.name}
                </p>
                <ColorsView colors={colors} handleRemove={handleRemove} />
                <ColorPicker
                  onChange={onChange}
                  handleAddColor={handleAddColor}
                  value={newColor}
                />
                <div className={classes.pantoneColorsCatalog}>
                  <p>
                    Don’t know your pantone color?{' '}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://swag-prod.s3.us-east-2.amazonaws.com/pantone_color_swatchbook.pdf"
                      className={classes.link}
                    >
                      Find it here.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DialogFooter className={classes.dialogFooter}>
          <SwagButton
            onClick={handleOpenChange}
            type="outlined"
            className={classes.button}
          >
            Cancel
          </SwagButton>
          {currentMockupIndex + 1 !== pantones?.length ? (
            <SwagButton type="primary" onClick={handleContinue}>
              Approve and Continue
            </SwagButton>
          ) : (
            <SwagButton type="primary" onClick={onConfirm}>
              Confirm
            </SwagButton>
          )}
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
