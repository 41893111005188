import { pushNotification } from 'redux/slices/notifications.slice';
import { makeToastNotification } from 'utils/query.util';
import {
  PER_PAGE_CARTS,
  DEFAULT_PAGE,
  DEFAULT_SORTING_ORDER,
} from 'constants/carts.constant';
import { cartApi } from 'redux/api/cart.api';
import { assigneeApi } from 'redux/api/assignee.api';
import { fetchUserData } from 'utils/user.util';
import { fetchStoreSettingsData } from 'utils/store-settings.util';
import { IAction } from 'interfaces/loaders.interface';

import { store } from '../../redux/store';

export async function cartsLoader(): Promise<null> {
  await fetchUserData();
  await fetchStoreSettingsData();

  const requests: Record<string, unknown>[] = [
    {
      endpoint: cartApi.endpoints.getCarts.initiate({
        page: DEFAULT_PAGE,
        perPage: PER_PAGE_CARTS,
        search: '',
        sorting: '',
        sortingOrder: DEFAULT_SORTING_ORDER,
        dropdown: [],
      }),
    },
    { endpoint: assigneeApi.endpoints.getAssigneeListForCarts.initiate() },
  ];

  try {
    const results = await Promise.all(
      requests.map(async (request) => {
        if (request?.condition === false) {
          return;
        }

        return store.dispatch(request.endpoint as IAction).unwrap();
      }),
    );

    if (results.some((result) => result && 'error' in result)) {
      return null;
    }
  } catch (error) {
    store.dispatch(pushNotification(makeToastNotification(error)));
  }

  return null;
}
