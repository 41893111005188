import { pushNotification } from 'redux/slices/notifications.slice';
import { makeToastNotification } from 'utils/query.util';
import { dashboardApi } from 'redux/api/dashboard.api';
import { store } from '../../redux/store';

export async function salesToolsLoader(): Promise<null> {
  try {
    await store
      .dispatch(
        dashboardApi.endpoints.getMyOrdersCounts.initiate(undefined, {
          forceRefetch: true,
        }),
      )
      .unwrap();
  } catch (error) {
    store.dispatch(pushNotification(makeToastNotification(error)));
  }

  return null;
}
