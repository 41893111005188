import { setItem, StorageKeys } from 'utils/window-storage.util';
import { storeSettingsApi } from 'redux/api/store-creation.api';
import { pushNotification } from 'redux/slices/notifications.slice';
import { technicalDataApi } from 'redux/api/technical-data.api';
import { ordersApi } from 'redux/api/orders.api';
import { makeToastNotification } from 'utils/query.util';
import { fetchUserData } from 'utils/user.util';
import { ACTION_TOKEN_TYPES } from 'constants/action-tokens.constant';

import { store } from 'redux/store';
import { authApi } from 'redux/api/auth.api';
import { getPublicSid } from 'utils/auth.util';
import { fetchBlogs } from 'redux/slices/blog.slice';
import { userApi } from 'redux/api/user.api';

export async function rootLoader(): Promise<null> {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  try {
    const request = store.dispatch(authApi.endpoints.getCSRFToken.initiate(null));
    const result = await request.unwrap();

    if ('error' in result) {
      return null;
    }

    setItem(StorageKeys.csrf, result.csrfToken);
  } catch (e) {
    store.dispatch(pushNotification(makeToastNotification(e)));
  }

  if (getPublicSid()) {
    try {
      const completedRequest = store.dispatch(
        storeSettingsApi.endpoints.getIsOnboardingCompleted.initiate(),
      );

      store.dispatch(fetchBlogs());
      const customersAndLeadsCountRequest = store.dispatch(
        userApi.endpoints.getCustomersAndLeadsCounts.initiate(),
      );
      const featureFlagsRequest = store.dispatch(
        technicalDataApi.endpoints.getFeatureFlags.initiate(),
      );
      await Promise.all([
        await fetchUserData(),
        await featureFlagsRequest.unwrap(),
        await completedRequest.unwrap(),
        await customersAndLeadsCountRequest.unwrap(),
      ]);
    } catch (e) {
      store.dispatch(pushNotification(makeToastNotification(e)));
    }
  }
  try {
    const action = urlParams.get('action');
    if (
      action &&
      [
        ACTION_TOKEN_TYPES.COLORS_REVIEW_BY_RESELLER,
        ACTION_TOKEN_TYPES.MOCKUP_APPROVAL_BY_RESELLER,
      ].includes(action)
    ) {
      const orderId = urlParams.get('orderId');
      const itemId = urlParams.get('itemId');
      const token = urlParams.get('token');

      const request = store.dispatch(
        ordersApi.endpoints.getActionOrder.initiate({
          action,
          orderId,
          itemId,
          token,
        }),
      );

      const result = await request.unwrap();

      if ('error' in result) {
        return null;
      }
    }
  } catch (e) {
    if (
      (e as { status?: number }).status !== 401 ||
      (e as { statusCode?: number }).statusCode !== 401
    ) {
      return null;
    }
    store.dispatch(pushNotification(makeToastNotification(e)));
  }

  return null;
}
