import cn from 'classnames';
import { useState } from 'react';
import { Dialog, DialogContentWrapper } from '@ezetech/swag-space-x';
import { useDispatch } from 'react-redux';
import { deepEqual } from 'utils/deep-equal';
import { closePopup } from 'redux/slices/modals.slice';
import { EditWhatWeOfferPagePopupProps } from 'interfaces/popup.interface';
import { storeLinkSelector, homePageSelector } from 'redux/selectors/settings.selectors';
import { useAppSelector } from 'redux/store';
import { useUpdateHomepageDataMutation } from 'redux/api/settings.api';
import { IHomepageSettings } from 'interfaces/homepage-settings.interface';
import {
  PageEditorPreview,
  PageEditorHeader,
  PageEditorContent,
  PageEditorSidebar,
  PageEditorLayout,
  PageEditorMobilePreviewButton,
} from 'components/page-editor';
import { can, SUBJECTS } from 'boot/ability';
import { deepOmit } from 'utils/deep-omit.util';

import { EditHomepageHeader } from './header';
import { EditHomepageSidebar, EDITOR_ID } from './sidebar';
import { queryBuilder, isMobileDevice, isTabletDevice, getHomepageObject } from './utils';
import classes from './edit-home-page-popup.module.scss';

export const EditHomePagePopup = ({
  isOpen,
}: EditWhatWeOfferPagePopupProps): JSX.Element => {
  const dispatch = useDispatch();
  const [saveLandingData] = useUpdateHomepageDataMutation();
  const homepageSettings = useAppSelector(homePageSelector);

  const storeLink = useAppSelector(storeLinkSelector);

  const [isChangedField, setIsChangedField] = useState(false);
  const [mobilePreview, setMobilePreview] = useState<boolean>(false);

  const [homepageInfoUpdated, setHomepageInfoUpdated] = useState(homepageSettings);
  const [aboutUsBlockButtonErrors, setAboutUsBlockButtonErrors] = useState({
    buttonText: '',
    buttonLink: '',
  });

  const disabledSaveButton =
    Object.values(aboutUsBlockButtonErrors).some((value) => value !== '') ||
    !isChangedField;

  const [landingUrl, setLandingUrl] = useState<string>(
    queryBuilder({ ...getHomepageObject(homepageSettings), storeLink }),
  );

  const [isPopupMounted, setPopupMounted] = useState(isOpen);

  const isEditable = can(
    SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.actions.HOMEPAGE_EDIT,
    SUBJECTS.PAGES_POPUPS_PDF_PRESENTATIONS.value,
  );

  const handleOnOpenChange = () => {
    setPopupMounted(false);
    setTimeout(() => {
      dispatch(closePopup());
    }, 500);
  };

  const onSaveChanges = async () => {
    const result = await saveLandingData(homepageInfoUpdated);
    if (!('error' in result)) {
      handleOnOpenChange();
    }
  };

  const handleUpdates = (settingsUpdated: IHomepageSettings) => {
    const isChanged = !deepEqual(
      getHomepageObject(homepageSettings) as unknown as never,
      settingsUpdated as unknown as never,
    );
    const isChangedForPreview = !deepEqual(
      deepOmit(
        getHomepageObject(homepageSettings),
        'aboutUsBlock.buttonLink',
      ) as unknown as never,
      deepOmit(settingsUpdated, 'aboutUsBlock.buttonLink') as unknown as never,
    );
    if (isChangedForPreview) {
      const query = queryBuilder({
        ...deepOmit(settingsUpdated, 'aboutUsBlock.buttonLink'),
        storeLink,
      });
      setLandingUrl(query);
    }
    setIsChangedField(isChanged);
    setHomepageInfoUpdated(settingsUpdated);
  };

  const onClickMobilePreview = () => {
    setMobilePreview((preview) => !preview);
  };

  return (
    <Dialog open={isPopupMounted} onOpenChange={handleOnOpenChange}>
      <DialogContentWrapper
        className={classes.dialogContentWrapperClassName}
        overlayClassName={classes.dialogOverlayClassName}
        type="vertical"
        id={EDITOR_ID}
      >
        <PageEditorLayout>
          <PageEditorHeader title="HOMEPAGE">
            {isEditable && (
              <PageEditorMobilePreviewButton
                mobilePreview={mobilePreview}
                onClick={onClickMobilePreview}
              />
            )}
            <EditHomepageHeader
              onClose={handleOnOpenChange}
              onSave={onSaveChanges}
              disabledSaveButton={disabledSaveButton}
              isEditMode={isEditable}
            />
          </PageEditorHeader>
          <PageEditorContent
            className={cn({
              [classes.contentWithoutSidebar]: !isEditable,
            })}
          >
            <PageEditorPreview
              src={landingUrl}
              className={classes.previewIframe}
              isHidden={isEditable && !mobilePreview && isMobileDevice()}
            />
            {isEditable && (
              <PageEditorSidebar
                title="Edit Page"
                className={classes.sidebar}
                isHidden={mobilePreview && isTabletDevice()}
              >
                <EditHomepageSidebar
                  settings={homepageSettings}
                  onSettingsChanged={handleUpdates}
                  aboutUsBlockButtonErrors={aboutUsBlockButtonErrors}
                  setAboutUsBlockButtonErrors={setAboutUsBlockButtonErrors}
                />
              </PageEditorSidebar>
            )}
          </PageEditorContent>
        </PageEditorLayout>
      </DialogContentWrapper>
    </Dialog>
  );
};
