import { CSSTransition } from 'react-transition-group';
import { useEffect, useState, createRef } from 'react';
import cn from 'classnames';
import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogContent,
  RainbowBox,
  DialogFooter,
  SwagButton,
} from '@ezetech/swag-space-x';
import { WithdrawPopupProps } from 'interfaces/popup.interface';
import { initialPayoutSelector } from 'redux/selectors/settings.selectors';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  closePopup,
  closePopupAndRedirectTo,
  openPopup,
} from 'redux/slices/modals.slice';
import { WithdrawWidget } from 'components/nav-bar/withdraw.component';
import { useRequestWithdrawMutation } from 'redux/api/withdraw.api';
import { ROUTES } from 'constants/router';
import { payoutFormSchema } from '../../../pages/settings/plan-and-payment/forms/payout/payout.schema';
import { WITHDRAW_SUCCESS_POPUP } from '../_logic/popups-list';
import styles from '../../nav-bar/nav-bar.module.scss';
import css from './withdraw.popup.module.scss';

enum Status {
  VALID = 'VALID',
  INVALID = 'INVALID',
}

const animationClasses = {
  enter: css.enter,
  enterActive: css.enterActive,
  exit: css.exit,
  exitActive: css.exitActive,
};

const renderButtonText = (status: Status | null) => {
  switch (status) {
    case Status.VALID:
      return 'Withdraw Now';
    case Status.INVALID:
      return 'Set Up Bank';
    default:
      return '';
  }
};

const renderText = (status: Status | null) => {
  switch (status) {
    case Status.VALID:
      return (
        <>
          The funds become available for withdrawal after an order goes into production.
          Withdrawals are deduced from your available balance and are paid out within 10
          business days.
        </>
      );
    case Status.INVALID:
      return (
        <>
          You currently do not have a withdrawal bank setup. Please visit your plan and
          payment settings to set this up before withdrawing.
        </>
      );
    default:
      return '';
  }
};

export const WithdrawPopup = ({ isOpen }: WithdrawPopupProps): JSX.Element => {
  const [isRequested, setAsRequested] = useState(false);
  const [isBankDetailsValid, setIsBankDetailsValid] = useState<Status | null>(null);

  const btnRef = createRef<HTMLSpanElement>();
  const textRef = createRef<HTMLSpanElement>();
  const dispatch = useAppDispatch();
  const [requestWithdraw] = useRequestWithdrawMutation();

  const payout = useAppSelector(initialPayoutSelector);

  useEffect(() => {
    if (payout) {
      try {
        // eslint-disable-next-line no-sync
        payoutFormSchema.validateSync(payout, { abortEarly: true });
        setIsBankDetailsValid(Status.VALID);
      } catch (e) {
        setIsBankDetailsValid(Status.INVALID);
      }
    }
  }, [payout]);

  const handleOnOpenChange = () => {
    dispatch(closePopup());
  };

  const onBtnClick = () => {
    if (isBankDetailsValid === Status.INVALID) {
      dispatch(closePopupAndRedirectTo({ route: ROUTES.SETTINGS_PLAN_AND_PAYMENT }));
      return;
    }

    requestWithdraw();
    setAsRequested(true);
    dispatch(closePopup());
    dispatch(openPopup({ popupName: WITHDRAW_SUCCESS_POPUP, popupProps: {} }));
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleOnOpenChange}>
      <DialogContentWrapper
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
      >
        <DialogTitle>Money owed to you</DialogTitle>
        <DialogDescription className={css.textWrapper}>
          <CSSTransition
            in={!!isBankDetailsValid}
            classNames={animationClasses}
            timeout={500}
            nodeRef={textRef}
            unmountOnExit
          >
            <span ref={textRef}>{renderText(isBankDetailsValid)}</span>
          </CSSTransition>
        </DialogDescription>
        <DialogContent>
          <RainbowBox borderWidth={6}>
            <WithdrawWidget
              popupView
              withoutSpace
              hideActionBtn
              pendingTextFont="bodyMd"
              incomingTextFont="bodyMd"
              pendingTextLineHeight="162%"
              incomingTextLineHeight="162%"
              pendingAmountFont="bodyMdBold"
              pendingAmountLineHeight="162%"
              incomingAmountLineHeight="162%"
              incomingAmountFont="bodyMdBold"
              classNameWrapperClassName={cn(
                styles.withdrawSectionWrapper,
                css.withdrawSectionWrapper,
              )}
              availableToWithdrawTextFont="bodyMd"
              availableToWithdrawTextLineHeight="162%"
              availableToWithdrawLinkLineHeight="162%"
              availableToWithdrawAmountLineHeight="100%"
              availableToWithdrawAmountFont="HEADING4XL"
              withdrawBottomSection={css.withdrawBottomSection}
              withdrawSectionClassName={css.withdrawSectionClassName}
            />
          </RainbowBox>
        </DialogContent>

        <DialogFooter>
          <SwagButton
            type="primary"
            onClick={onBtnClick}
            className={css.btn}
            disabled={isBankDetailsValid === Status.VALID && isRequested}
          >
            <CSSTransition
              in={!!isBankDetailsValid}
              classNames={animationClasses}
              timeout={500}
              nodeRef={btnRef}
              unmountOnExit
            >
              <span ref={btnRef}>{renderButtonText(isBankDetailsValid)}</span>
            </CSSTransition>
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
