import { Typography } from '@ezetech/swag-space-x';
import { useCallback, useState, useEffect } from 'react';
import cn from 'classnames';
import { IoClose } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import { useLogoutMutation } from 'redux/api/auth.api';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { ROUTES, ROUTES_NAMES, EXTERNAL_ROUTES } from 'constants/router';

import { useLogoutAsReseller } from 'hooks/use-logout-as-reseller.hook';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { adminLoggedInAsResellerSelector } from 'redux/selectors/user.selectors';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import { isShowReferLink, isShowSettingsLink } from 'utils/policy.util';

import { CONFIRMATION_POPUP } from '../popups/_logic/popups-list';
import css from './header-side-menu.module.scss';

export type HeaderSideMenuComponentProps = {
  open: boolean;
  onClose: () => void;
};

export function HeaderSideMenuComponent({
  open = false,
  onClose,
}: HeaderSideMenuComponentProps): JSX.Element {
  const dispatch = useAppDispatch();
  const [logout] = useLogoutMutation();
  const adminLoggedInAsReseller = useAppSelector(adminLoggedInAsResellerSelector);
  const logoutAsReseller = useLogoutAsReseller();
  const [showReferLink, setShowReferLink] = useState(false);
  const [showSettingsLink, setShowSettingsLink] = useState(false);

  const onLogout = useCallback(() => {
    if (adminLoggedInAsReseller) {
      dispatch(
        openPopup({
          popupName: CONFIRMATION_POPUP,
          popupProps: {
            descriptionComponent:
              'Are you sure you want to log out from reseller dashboard?',
            onConfirm: () => {
              logoutAsReseller();
              dispatch(closePopup());
            },
            onCancel: () => {
              dispatch(closePopup());
            },
          },
        }),
      );
    } else {
      logout();
    }
  }, []);

  useEffect(() => {
    setShowReferLink(isShowReferLink());
    setShowSettingsLink(isShowSettingsLink());
  }, []);

  const handleOnClick = useCallback(() => {
    typeof onClose === 'function' && onClose();
  }, []);

  return (
    <>
      <div
        className={cn({
          [css.isOpen]: open,
        })}
      >
        <div className={css.layout} onClick={handleOnClick}></div>
        <div className={css.sidebar}>
          <div>
            <div className={css.sideHeader}>
              <Typography
                color={COLORS.colorPrimaryText}
                lineHeight="100%"
                fontType="HEADING4XL"
              >
                Menu
              </Typography>
              <div>
                <IoClose
                  color="#a6a6a6"
                  size={35}
                  className={css.sideClose}
                  onClick={handleOnClick}
                />
              </div>
            </div>
            <div className={css.sideItem}>
              <Link
                data-option={ROUTES.PROFILE_SETTINGS}
                to={ROUTES.PROFILE_SETTINGS}
                className={css.unlink}
                onClick={onClose}
              >
                <Typography
                  color={COLORS.colorBlack}
                  lineHeight="162%"
                  fontType="bodyMdBold"
                >
                  {ROUTES_NAMES.PROFILE_SETTINGS}
                </Typography>
              </Link>
            </div>
            {showSettingsLink && (
              <div className={css.sideItem}>
                <Link
                  data-option={ROUTES.SETTINGS_COMPANY_AND_BRAND}
                  to={ROUTES.SETTINGS_COMPANY_AND_BRAND}
                  className={css.unlink}
                  onClick={onClose}
                >
                  <Typography
                    color={COLORS.colorBlack}
                    lineHeight="162%"
                    fontType="bodyMdBold"
                  >
                    Company Settings
                  </Typography>
                </Link>
              </div>
            )}
            <div className={css.sideItem}>
              <a
                data-option={EXTERNAL_ROUTES.EDUCATION_HUB}
                target="_blank"
                rel="noreferrer noopener"
                href={EXTERNAL_ROUTES.EDUCATION_HUB}
                className={css.unlink}
                onClick={onClose}
              >
                <Typography
                  color={COLORS.colorBlack}
                  lineHeight="162%"
                  fontType="bodyMdBold"
                >
                  {ROUTES_NAMES.EDUCATION_HUB}
                </Typography>
              </a>
            </div>
            {showReferLink && (
              <div className={css.sideItem}>
                <Link
                  data-option={ROUTES.REFER_A_MEMBER}
                  to={ROUTES.REFER_A_MEMBER}
                  className={css.unlink}
                  onClick={onClose}
                >
                  <Typography
                    color={COLORS.colorBlack}
                    lineHeight="162%"
                    fontType="bodyMdBold"
                  >
                    {ROUTES_NAMES.REFER_A_MEMBER}
                  </Typography>
                </Link>
              </div>
            )}
            <div className={css.sideItem} onClick={onLogout}>
              <Typography
                color={COLORS.colorBlack}
                lineHeight="162%"
                fontType="bodyMdBold"
              >
                <span data-option="logout">Logout</span>
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
